const config = {
  apiKey: "AIzaSyC703u_U97thTlz2YJhVlqnKXr-2lNi-NM",
  authDomain: "glorireels-d7606.firebaseapp.com",
  projectId: "glorireels-d7606",
  storageBucket: "glorireels-d7606.appspot.com",
  messagingSenderId: "388454931606",
  appId: "1:388454931606:web:a6ff1d01c93a41005576b7",
  measurementId: "G-DK6RWHRZ1V"
};

export default config